import React, { Component } from 'react'

class Footer extends Component{
    render(){
        return(
            <div>
                <footer className="footer-setion">
                    <div className="footer-top pt-5 pb-5">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-4 mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">
                                        <p><strong>Lionheart Media</strong></p>

                                        <ul className="list-inline">
                                            <li className="list-inline-item"><span className="ti-arrow-circle-right mr-2"></span> <a href="/#">Privacy
                                                policy</a></li>
                                            <li className="list-inline-item"><span className="ti-arrow-circle-right mr-2"></span> <a href="/#">Terms
                                                and Conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">                                       
                                        <ul className="list-unstyled">                                 
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-nav-wrap">
                                        <h5 className="mb-3">Quick links</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/about-us">About Us</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/services">Our Services</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/pricing">Pricing</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/contact">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom gray-light-bg pt-4 pb-4">
                        <div className="container">
                            <div className="row text-center text-md-center align-items-center">
                                <div className="col-md-6 col-lg-12"><p className="text-md-center copyright-text pb-0 mb-0">All
                                    rights reserved by LIONHEART GLOBAL CORPORATION PTY LTD</p><p>Company Number 659 392 971</p></div>                  
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer;