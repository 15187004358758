import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import Theme3 from "../themes/theme3";
import AboutUs from '../themes/about-us';
import Services from '../themes/services';
import Pricing from '../themes/pricing';
import contact from '../themes/contact';

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme3} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}


/* <Route exact path="/about-us" component={AboutUs} />
<Route exact path="/services" component={Services} />
<Route exact path="/pricing" component={Pricing} />
<Route exact path="/contact" component={contact} /> */